<template>
	<div class="register">

		<el-form :model="registerData" status-icon ref="registerData">
			<img src="@/assets/img/np_login_logo.svg" alt="" class="logo">
			
			<el-form-item v-if="!isNext">
				<el-input type="text" label="Email" placeholder="Email" v-model="registerData.email"></el-input>
			</el-form-item>
			<el-form-item class="captcha-wrap" v-if="!isNext" >
				<el-input v-model="registerData.captchaCode"  placeholder="Captcha code"></el-input>
				<img :src="'data:image/jpeg;base64,'+captchaImage" @click="refreshImg" alt="" class="captcha">
			</el-form-item>
			<el-form-item class="captcha-wrap" v-if="!isNext" >
				<el-input v-model="registerData.emailCode"  placeholder="Email code"></el-input>
				<el-button @click="sendEmailCode" :disabled="timerTf" v-if="!timerTf">Send</el-button>
				<el-button v-else disabled>{{countDownNum}} S</el-button>
			</el-form-item>
			<el-form-item  v-if="isNext" >
				<el-input type="password"  placeholder="Create password" v-model="registerData.password"></el-input>
			</el-form-item>
			<el-form-item  v-if="isNext" >
				<el-input type="password" placeholder="Confirm password" v-model="registerData.confirmPassword"></el-input>
			</el-form-item>
			<el-button @click="registerNext" v-if="!isNext">Next</el-button>		
			<el-button @click="registerSubmit" v-else >Submit</el-button>		
			<div>
				<div class="register-footer">Already have an Account? <router-link to="/login">Sign in here</router-link></div>
		   </div>	
		</el-form>
		
	</div>
</template>

<script>
import { getCaptchaImageHttp,sendEmailRegisterHttp,registerHttp,checkCodeRegisterHttp } from '@/api/getData'
import { isEmail} from '@/utils/validate'

export default {
	name: 'Register',
	data() {
		return {
			registerData: {
				captchaCode:'',
				email: '',
				emailCode:'',
				password:"",
				confirmPassword:''
			},
			captchaImage:"",
			timerTf: false,
			countDownNum: 600,
			isNext: false
		}
	},
	mounted(){
		this.getCaptchaImage()
	},
	methods: {
		//提交注册
		registerSubmit(){
			if(!this.registerData.email){
				this.$message.error("Email is required");
				return;
			}
			if(!isEmail(this.registerData.email)){
				this.$message.error("Email is invalid");
				return;
			}
			if(!this.registerData.emailCode){
				this.$message.error("Email code is required");
				return;
			}
			if(!this.registerData.password){
				this.$message.error("Password code is required");
				return;
			}
			if (this.registerData.password != this.registerData.confirmPassword) {
				this.$message.error("Confirm Password  error");
				return;
			}

			let data = {
				registerCode: this.registerData.emailCode,
				email: this.registerData.email,
				password: this.registerData.password
			}
			registerHttp(data).then(({data})=>{
				this.$store.commit("Logout");
                this.$router.replace("/login");
				this.$message.success('Successful')
			})

		},
		//下一步
		registerNext(){
			if(!this.registerData.email){
				this.$message.error("Email is required");
				return;
			}
			if(!isEmail(this.registerData.email)){
				this.$message.error("Email is invalid");
				return;
			}
			if(!this.registerData.captchaCode){
				this.$message.error("Captcha code is required");
				return;
			}
			if(!this.registerData.emailCode){
				this.$message.error("Email code is required");
				return;
			}
			let data = {
				registerCode: this.registerData.emailCode,
				email: this.registerData.email,
				password: "checkCode"
			}
			checkCodeRegisterHttp(data).then(({data})=>{
				this.isNext  = true
			})			
		},

		//发送注册验证码
		sendEmailCode(){
			if(!this.registerData.email){
				this.$message.error("Email is required");
				return;
			}
			if(!isEmail(this.registerData.email)){
				this.$message.error("Email is invalid");
				return;
			}
			if(!this.registerData.captchaCode){
				this.$message.error("Captcha code is required");
				return;
			}
			let data = {
				captchaKey: this.gVerifycode,
				captchaCode: this.registerData.captchaCode,
				email: this.registerData.email
			}
			sendEmailRegisterHttp(data).then(({data})=>{
				this.timerTf = true
				this.countDownNum = 600
				this.timer = setInterval(() => {
					this.countDownNum--
					if (this.countDownNum <= 0) {
						this.timerTf = false
						clearInterval(this.timer)
					}
				}, 1000)
				this.$message.success("Email has been sent, Please check it!")
			})
		},
		//图形验证码
		getCaptchaImage() {
			getCaptchaImageHttp().then(res => {
				this.captchaImage = res.data
			})
		},
		//刷新图形验证码
		refreshImg() {
			this.getCaptchaImage()
		},
		
	}
}
</script>

<style lang="scss" scoped>
.register {

	.el-form::v-deep {
		width: 370px;
		height: 400px;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		margin-bottom: 20px;

		.logo {
			width: 227px;
			height: 43px;
			margin-bottom: 21px;
		}

		.title {
			font-size: 1.04vw;
			color: #b90025;
			letter-spacing: 2.4px;
			margin-bottom: 100px;
		}

		.el-form-item {
			margin-bottom: 20px;
		}

		.el-input__inner {
			height: 48px;
			padding: 0 20px;
			font-size: 0.93vw;
		}

		.el-button {
			width: 370px;
			height: 50px;
			background: #b90025;
			border-radius: 6px;
			margin-top: 30px;
			font-size: 1.04vw;
			font-weight: 400;

			span {
				color: #ffffff;
			}
		}
		.captcha-wrap {
			.el-input {
				width: 250px;
			}

			.el-form-item__content {
				display: flex;
				justify-content: space-between;
			}

			.el-input__inner {
				color: #092020;
			}
			.captcha {
				height: 48px;
				width: 120px;
				border-radius: 6px
			}
			.el-button{
				width: 120px;
				margin-top: 0px;
			}
		}

  
	}
	.register-footer{
		text-align: center;
		margin-top: 15px;
		font-size: 0.72vw;
		a{
			color: #b90025;
			text-decoration:underline;
		}
	}
}
</style>